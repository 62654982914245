import { useApolloQuery } from '@/gql/apolloWrapper';
import {
  BillingPaymentMethod,
  PaymentMethodStatus,
  PaymentMethodType,
  ProductKey,
  useGetBillingInfoPaymentMethodsQuery,
} from '@/gql/generated';
import useCurrentCompany from '@/hooks/useCurrentCompany/useCurrentCompany';
import { computed, ref, watch } from '@nuxtjs/composition-api';

const { companyId, company } = useCurrentCompany();

const useProductCopy = () => {
  const paymentMethods = ref<BillingPaymentMethod[]>([]);
  const isLoading = ref(false);

  const unwatch = watch(
    companyId,
    (id) => {
      if (id) {
        useApolloQuery(
          useGetBillingInfoPaymentMethodsQuery,
          { input: { companyId: companyId.value } },
          {
            data: paymentMethods,
            pending: isLoading,
          },
          {
            placeholderPick: ({ getBillingInfo: res }) => res.paymentMethods,
            force: true,
          }
        );

        unwatch?.();
      }
    },
    { immediate: true }
  );

  const hasACH = computed(() =>
    paymentMethods.value?.some((m) => {
      return (
        m.type === PaymentMethodType.BankAccount &&
        m.status === PaymentMethodStatus.Active
      );
    })
  );

  const isAboveTierThreshold = computed(
    () => Number(company.value.plan?.slice(-1)) > 3
  );

  const copy = computed(() => ({
    [ProductKey.ManagedHrServices]: {
      title: 'Managed Services',
      description:
        'Our Bambee HR Managers are on your side, ready to handle the difficult talks and situations on your behalf.',
      features: [
        'Direct Bambee HR Involvement',
        'Guided Compliance Management',
        'Cost-Effective Engagement',
        'Compliance Assurance',
        'Audit Ready Documentation',
        'Operational Peace of Mind',
      ],
      img: '/images/hand-shake-doc.svg',
    },
    [ProductKey.WebinarWorkplaceViolence]: {
      title: 'Workplace Violence Protection Training Program ',
      shortTitle: 'Workplace Violence Webinar',
      description: `Expert guidance, custom policies, and essential training tools to meet workplace violence prevention requirements.`,
      features: [
        'Workplace Violence Prevention Masterclass',
        'Workplace Prevention Strategy Session',
        'Workplace Violence Prevention Plan',
        'OSHA Incident Log Template',
        'OSHA Hazard Log Template',
        'Training Log Templates',
      ],
      purchaseCta: 'Purchase',
    },
    [ProductKey.Payroll]: {
      img: '/images/payroll.png',
      title: 'Bambee Payroll™',
      shortTitle: 'Bambee Payroll™',
      description: `Streamline your payroll with Bambee's new tailored solutions. Whether you need basic or premium services, we've got you covered. Choose Premium for added support and exclusive features.`,
      features: [
        'Easy-to-use Payroll for Small Businesses',
        'Chat Support',
        'Dedicated Payroll Manager <span class="text-premium-gold">(Premium Exclusive)</span>',
        'Comprehensive Wage Guidance <span class="text-premium-gold">(Premium Exclusive)</span>',
        'Next-Day Phone Support Guarantee <span class="text-premium-gold">(Premium Exclusive) </span>',
      ],
      purchaseCta: 'Select',
      legalCopy:
        'In order for Bambee to be a strategic partner, we require customers to subscribe to Bambee Payroll.',
      CtaIcon: 'lockUnlocked',
    },
  }));

  return {
    copy,
    hasACH,
    isAboveTierThreshold,
  };
};

export default useProductCopy;
